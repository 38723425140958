import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <p className="footer__text">
          <a href="/">トップ</a>
        </p>
        <p className="footer__text">
          <a href="/tags/">音楽につけられたタグ一覧</a>
        </p>
        <p className="footer__text">
          <a href="/blog/">ブログ</a>
        </p>
        <p className="footer__text">
          <a href="/faq/">よくあるご質問</a>
        </p>
        <p className="footer__text">
          <a href="/terms/">利用規約</a>
        </p>
        <p className="footer__text">
          <a href="/privacy/">プライバリーポリシー</a>
        </p>
        <p className="footer__text">
          <a href="/about/">作曲者について</a>
        </p>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()}{' '}
          <a href="https://shinshin86.com/" target="_blank" rel="noopener noreferrer">
            Yuki Shindo
          </a>
        </p>
        <p className="footer__text">
          © {new Date().getFullYear()} - Template developed by{' '}
          <a href="https://github.com/cobidev" target="_blank" rel="noopener noreferrer">
            Jacobo Martínez
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
